import { useEffect } from "react";

const Notification = ({ setShowNotification, message, isError }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNotification(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [setShowNotification]);
  return (
    <div
      className={`fixed top-16 right-4 p-4 bg-blue-100  border ${
        isError
          ? `text-red-800 border-red-300`
          : `text-blue-800 border-blue-300`
      } rounded-md shadow-xl transition-transform transform duration-500 ease-in-out translate-y-0 opacity-100`}
    >
      <div className="flex items-center">
        <svg
          className="w-6 h-6 mr-2 text-blue-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 12l2 2 4-4"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 2a10 10 0 110 20 10 10 0 010-20z"
          ></path>
        </svg>
        <p className="font-semibold">{message}</p>
      </div>
    </div>
  );
};

export default Notification;
