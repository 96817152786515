import React from "react";

const ErrorModal = ({ errorMessage }) => {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-80 flex items-center justify-center">
      <div className="bg-gradient-to-r from-red-400 to-red-500 p-8 rounded-lg shadow-xl transform transition duration-500 scale-105 text-center">
        <div className="bg-white p-4 rounded-full mx-auto w-24 h-24 flex items-center justify-center shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-red-500 animate-shake"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v2m0 4h.01M12 3a9 9 0 100 18 9 9 0 000-18z"
            />
          </svg>
        </div>
        <h3 className="mt-6 text-2xl font-extrabold text-white">
          Upload Failed
        </h3>
        <p className="text-white mt-2 text-lg">{errorMessage}</p>
      </div>
    </div>
  );
};
export default ErrorModal;
