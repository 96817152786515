import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { EyeIcon } from "@heroicons/react/24/outline";
import { getStatusBadgeClass, truncateText } from "../../utils/Utils";
import Loader from "../../components/Loader";

const AdminDashboard = () => {
  const [files, setFiles] = useState([]);
  const [dashboard, setDashboard] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get(`/dashboard`);
        setDashboard(response.data);
        setFiles(response.data.files);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  const filteredFiles = files.filter(
    (file) =>
      file.file_id
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      file.file_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-6 dark:bg-gray-900 dark:text-gray-100">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <div className="flex flex-wrap -mx-6">
        <div className="w-full px-6 sm:w-1/2 xl:w-1/3">
          <div className="flex items-center px-5 py-6 bg-white dark:bg-gray-800 rounded-md shadow-sm">
            <div className="p-3 bg-green-600 text-white bg-opacity-75 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
            </div>

            <div className="mx-5">
              <h4 className="text-2xl font-semibold text-gray-700 dark:text-gray-100">
                {loading ? "..." : dashboard?.completed}
              </h4>
              <div className="text-gray-500 dark:text-gray-400">Completed</div>
            </div>
          </div>
        </div>

        <div className="w-full px-6 mt-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
          <div className="flex items-center px-5 py-6 bg-white dark:bg-gray-800 rounded-md shadow-sm">
            <div className="p-3 bg-pink-600 text-white bg-opacity-75 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                />
              </svg>
            </div>

            <div className="mx-5">
              <h4 className="text-2xl font-semibold text-gray-700 dark:text-gray-100">
                {loading ? "..." : dashboard?.refunded}
              </h4>
              <div className="text-gray-500 dark:text-gray-400">Refunded</div>
            </div>
          </div>
        </div>

        <div className="w-full px-6 mt-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
          <div className="flex items-center px-5 py-6 bg-white dark:bg-gray-800 rounded-md shadow-sm">
            <div className="p-3 bg-red-600 text-white bg-opacity-75 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>

            <div className="mx-5">
              <h4 className="text-2xl font-semibold text-gray-700 dark:text-gray-100">
                {loading ? "..." : dashboard?.canceled}
              </h4>
              <div className="text-gray-500 dark:text-gray-400">Canceled</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8"></div>

      {loading ? (
        <div className="container mx-auto p-6">
          <Loader />
        </div>
      ) : (
        <>
          {/* Search Bar */}
          <div className="mb-4">
            <input
              type="text"
              className="border border-gray-300 dark:border-gray-700 p-2 w-full rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Search by file id, file name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="flex flex-wrap">
            {/* Table for displaying files */}
            <div className="w-full md:w-1/2 p-2 pl-0">
              <h2 className=" font-bold mb-4">Last Files</h2>
              <table className="min-w-full table-auto bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md">
                <thead>
                  <tr className="bg-gray-100 dark:bg-gray-700">
                    <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                      File ID
                    </th>
                    <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                      Vehicle
                    </th>
                    <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                      File Name
                    </th>
                    <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                      Status
                    </th>
                    <th className="px-4 py-2 border-b dark:border-gray-600 text-center">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredFiles?.map((file) => (
                    <tr
                      key={file.id}
                      className="hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="px-4 py-2 border-b dark:border-gray-600">
                        {file.file_id}
                      </td>
                      <td className="px-4 py-2 border-b dark:border-gray-600">
                        {file.brand}
                      </td>
                      <td className="px-4 py-2 border-b dark:border-gray-600">
                        {truncateText(file.file_name, 10)}
                      </td>
                      <td className="px-4 py-2 border-b dark:border-gray-600">
                        <span
                          className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                            file.status
                          )}`}
                        >
                          {file.status}
                        </span>
                      </td>
                      <td className="px-4 py-2 border-b dark:border-gray-600 text-center">
                        <button className="text-blue-500 py-1 rounded dark:text-blue-400">
                          <Link to={`/admin/view-file/${file.id}`}>
                            <EyeIcon className="h-5 w-5 mr-1" />
                          </Link>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Table for displaying tickets */}
            <div className="w-full md:w-1/2 p-2 pr-0">
              <h2 className=" font-bold mb-4">Last Tickets</h2>
              <table className="min-w-full table-auto bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md">
                <thead>
                  <tr className="bg-gray-100 dark:bg-gray-700">
                    <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                      Ticket ID
                    </th>
                    <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                      Subject
                    </th>
                    <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                      Status
                    </th>
                    <th className="px-4 py-2 border-b dark:border-gray-600 text-center">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dashboard?.tickets?.map((ticket) => (
                    <tr
                      key={ticket.id}
                      className="hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="px-4 py-2 border-b dark:border-gray-600">
                        {ticket.ticket_id}
                      </td>
                      <td className="px-4 py-2 border-b dark:border-gray-600">
                        {ticket.subject}
                      </td>
                      <td className="px-4 py-2 border-b dark:border-gray-600">
                        <span
                          className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                            ticket.status
                          )}`}
                        >
                          {ticket.status}
                        </span>
                      </td>
                      <td className="px-4 py-2 border-b dark:border-gray-600 text-center">
                        <button className="text-blue-500 py-1 rounded dark:text-blue-400">
                          <Link to={`/admin/view-file/${ticket.file_id}`}>
                            <EyeIcon className="h-5 w-5 mr-1" />
                          </Link>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
