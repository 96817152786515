import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../components/ErrorAlert";
import SuccessAlert from "../components/SuccessAlert";
import axiosInstance from "../utils/axiosInstance";
import { useSearchParams } from "react-router-dom";
import logo from "../assets/logo.png";

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      setLoading(true);
      const response = await axiosInstance.post("/auth/reset-password", {
        token: token,
        password: password,
      });
      setMessage("Password successfully reset");
      setLoading(false);
      navigate("/login");
    } catch (err) {
      setLoading(false);
      if (err.response.status === 400) {
        setErrorMessage(err.response.data.message);
        return;
      }
      setErrorMessage("There was a problem resetting your password");
    }
  };

  return (
    <div className={`min-h-screen flex bg-white dark:bg-gray-900`}>
      {/* Left Section - Wallpaper */}
      <div
        className="w-2/3 bg-cover bg-center"
        style={{
          backgroundImage:
            'url("https://wallpapers.com/images/hd/1920x1080-hd-car-bvswz2skd2mquyjc.jpg")',
        }}
      >
        {/* Replace with your preferred image URL */}
      </div>

      {/* Right Section - Reset Password Form */}
      <div className="w-2/3 flex items-center justify-center">
        <div className="w-full max-w-md px-8 py-10 bg-white shadow-lg rounded-lg dark:bg-gray-800">
          {/* Logo */}
          <div className="flex flex-col items-center mb-4">
            <img src={logo} alt="Logo" className="w-12 h-auto mb-4" />
            <h1 className="text-3xl font-bold text-red-600">VDP Tuning Team</h1>
          </div>

          {/* Instruction Text */}
          <p className="text-sm text-center text-gray-600 dark:text-gray-400 mb-6">
            Please set your new password.
          </p>

          {message && <SuccessAlert message={message} />}
          {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
          <br />

          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="password"
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                required
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                required
              />
            </div>
            <div>
              <button
                className="w-full py-2 bg-red-600 text-white font-bold rounded-md hover:bg-red-700 dark:bg-red-500 dark:hover:bg-red-600"
                disabled={loading}
              >
                {loading ? "Sending..." : "Reset Password"}
              </button>
            </div>
          </form>

          <div className="mt-4 text-center">
            <p className="text-gray-600 dark:text-gray-400">
              Back to{" "}
              <a href="/login" className="text-red-600 hover:underline">
                Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
