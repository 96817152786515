import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

const Section = ({ title, items }) => (
  <div className="mb-8">
    <h2 className="text-xl font-semibold mb-4">{title}</h2>
    <div className="flex flex-wrap gap-4">
      {items.map((item) => (
        <div
          key={item.id}
          className="flex bg-white shadow-md rounded-lg p-4 items-center w-full md:w-1/2 lg:w-1/3 xl:w-1/4 dark:bg-gray-800"
        >
          <img
            src={item.image_url}
            alt={item.name}
            className="w-24 h-24 object-cover rounded-md mr-4"
          />
          <div className="flex-grow overflow-hidden py-1">
            <h3 className="text-lg font-bold truncate" title={item.name}>
              {item.name}
            </h3>
            <p
              className="text-sm text-gray-600 dark:text-gray-300 truncate"
              title={item.code}
            >
              {item.code}
            </p>
            <p className="mt-2">
              <span className="bg-red-500 text-white px-2 py-1 rounded-full text-md font-semibold">
                Credits: {item.credits}
              </span>
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const PriceList = () => {
  const [solutions, setSolutions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get("/solutions")
      .then((response) => {
        setLoading(false);
        setSolutions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching solutions:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  const groupedSolutions = solutions.reduce((acc, item) => {
    acc[item.type] = acc[item.type] || [];
    acc[item.type].push(item);
    return acc;
  }, {});

  return (
    <div className="container mx-auto p-6 dark:bg-gray-900 dark:text-white">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Price List</h1>
        <Link
          to={"/buy-credits"}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Buy Credits
        </Link>
      </div>
      {Object.entries(groupedSolutions).map(([type, items]) => (
        <Section key={type} title={type} items={items} />
      ))}
    </div>
  );
};

export default PriceList;
