import React, { useState } from "react";
import axiosInstance from "../utils/axiosInstance";

const DownloadFileButton = ({ fileId, fileName }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/files/${fileId}/download`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      setLoading(false);
    } catch (error) {
      console.error("Error while downloading the file:", error);
    }
  };

  return (
    <div className="mt-6">
      <button
        onClick={handleDownload}
        className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg flex items-center gap-2"
        disabled={loading}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3 4a2 2 0 012-2h10a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V4zm5 4a1 1 0 000 2h4a1 1 0 000-2H8z"
            clipRule="evenodd"
          />
        </svg>
        {loading ? "Downloading..." : "Download Original File"}
      </button>
    </div>
  );
};

export default DownloadFileButton;
