import React, { useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";

const SolutionImageModal = ({ setShowAddImageModal }) => {
  const [imageFile, setImageFile] = useState(null);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageUpload = async (file) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);

    try {
      await axiosInstance.post("/solutions/image-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
      });

      setShowAddImageModal(false);
      setImageFile(null);
      setUploadProgress(0);
    } catch (err) {
      setError(err.response.data.message);
      setUploadProgress(0);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      handleImageUpload(file);
    }
  };

  const handleCloseImageModal = () => {
    setShowAddImageModal(false);
    setImageFile(null);
    setUploadProgress(0);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-lg font-semibold mb-4">Upload Image Solution</h2>
        <label className="block w-full h-40 border-2 border-dashed border-blue-500 rounded-lg flex items-center justify-center cursor-pointer hover:bg-blue-50">
          <span className="text-blue-500 text-4xl font-bold">+</span>
          <input type="file" className="hidden" onChange={handleFileChange} />
        </label>
        {uploadProgress > 0 && (
          <div className="mt-4 w-full bg-gray-200 rounded-full">
            <div
              className="h-4 rounded-full"
              style={{
                width: `${uploadProgress}%`,
                backgroundColor:
                  uploadProgress < 50
                    ? "red"
                    : uploadProgress < 80
                    ? "yellow"
                    : "green",
              }}
            ></div>
          </div>
        )}
        {error && <p className="text-red-500 mt-4">{error}</p>}
        <div className="flex justify-end space-x-4 mt-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            onClick={handleCloseImageModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SolutionImageModal;
