import React, { useState, useEffect } from "react";
import Loader from "../components/Loader";

const GainCar = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tuning-shop.com/media/js/iframe-loader.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="p-6 min-h-screen">
      <iframe
        src="https://tuning-shop.com/iframe/iframe.php?user=5820"
        width="100%"
        height="auto"
        scrolling="no"
        frameborder="0"
        style={{ border: 0 }}
      ></iframe>
    </div>
  );
};

export default GainCar;
