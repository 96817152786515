import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";

const EmailForm = () => {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [emails, setEmails] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [enableRecipients, setEnableRecipients] = useState(false);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axiosInstance.get("/emails");
        setEmails(response.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };
    fetchEmails();
  }, []);

  const handleCheckboxChange = (email) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(email)) {
        return prevSelectedUsers.filter((e) => e !== email);
      } else {
        return [...prevSelectedUsers, email];
      }
    });
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axiosInstance.post("/emails/send", {
        subject,
        body,
        recipients: selectedUsers,
      });
      if (response.status === 200) {
        setMessage("Emails sent successfully!");
      }
    } catch (error) {
      setMessage("Failed to send emails.");
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex justify-center bg-gray-200 my-12">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-md w-full">
        <h2 className="text-2xl font-semibold text-center mb-6">
          Send Email to Selected Users
        </h2>
        {message && (
          <p
            className={`text-center mb-4 ${
              message.includes("successfully")
                ? "text-green-600"
                : "text-red-600"
            }`}
          >
            {message}
          </p>
        )}
        <form onSubmit={handleSendEmail}>
          <div className="mb-4">
            <label
              htmlFor="subject"
              className="block text-gray-700 font-medium mb-2"
            >
              Subject
            </label>
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="body"
              className="block text-gray-700 font-medium mb-2"
            >
              Body (supports HTML)
            </label>
            <textarea
              id="body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              rows="6"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={enableRecipients}
                onChange={(e) => setEnableRecipients(e.target.checked)}
                className="mr-2"
              />
              <span className="text-gray-700 font-medium">
                Select Specific Recipients
              </span>
            </label>
            <div
              className={`h-32 overflow-y-scroll border rounded-md p-2 ${
                enableRecipients ? "" : "bg-gray-100 cursor-not-allowed"
              }`}
            >
              {emails.map((email) => (
                <div key={email} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={`user-${email}`}
                    checked={selectedUsers.includes(email)}
                    onChange={() => handleCheckboxChange(email)}
                    disabled={!enableRecipients}
                    className="mr-2"
                  />
                  <label htmlFor={`user-${email}`} className="text-gray-700">
                    {email}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-200"
            disabled={isSubmitting || selectedUsers.length === 0}
          >
            {isSubmitting ? "Sending..." : "Send Email"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EmailForm;
