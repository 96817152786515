import React, { useState, useContext } from "react";
import ErrorModal from "../components/ErrorModal";
import SuccessModal from "../components/SuccessModal";
import ChooseSolution from "../components/ChooseSolution";
import axiosInstance from "../utils/axiosInstance";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import ErrorAlert from "../components/ErrorAlert";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import ProgressBar from "../components/ProgressBar";

const FileUpload = () => {
  const { updateUserLocal, resetUser } = useContext(AuthContext);
  const [file, setFile] = useState(null);
  const [formEnabled, setFormEnabled] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedSolutionIds, setSelectedSolutionIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size <= 20 * 1024 * 1024) {
      setFile(selectedFile);
      setFormEnabled(true);
      setErrorMessage("");
    } else {
      setErrorMessage("File size should not exceed 20MB.");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.size <= 20 * 1024 * 1024) {
      setFile(droppedFile);
      setFormEnabled(true);
      setErrorMessage("");
    } else {
      setErrorMessage("File size should not exceed 20MB.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert("Please select a file first.");
      return;
    }

    setProgress(0);

    const jsonData = {
      vehicle: document.getElementById("type").value,
      brand: document.getElementById("brand").value,
      model: document.getElementById("model").value,
      ecu: document.getElementById("ecu").value,
      petrol_type: document.getElementById("petrol_type").value,
      generation: document.getElementById("generation").value,
      gear: document.getElementById("gear").value,
      reading_device: document.getElementById("reading_device").value,
      expected: document.getElementById("expected").value,
      solution: selectedOptions.join(", "),
      solution_ids: selectedSolutionIds,
      price: 0,
    };

    if (jsonData.solution.trim() === "") {
      setErrorMessage(
        "You must select at least one solution to upload the file."
      );
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("jsonData", JSON.stringify(jsonData));

    try {
      await axiosInstance.post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (progressEvent.loaded === progressEvent.total) {
            percentCompleted = 100;
          }

          setProgress(percentCompleted);
        },
      });
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
        navigate("/my-files");
      }, 3000);

      setFile(null);
      setFormEnabled(false);
      setSelectedOptions([]);
      setErrorMessage("");
      setProgress(0);

      event.target.reset();
      updateUserLocal();
    } catch (error) {
      console.log(error);
      resetUser();
      if (error.response?.status === 400) {
        setErrorMessage(error.response.data.message);
        return;
      }
      setErrorModal(true);
      setTimeout(() => setErrorModal(false), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      {/* Progress Bar */}
      {isLoading && <ProgressBar progress={progress} />}
      <div className="p-6 bg-white dark:bg-gray-800 border-md shadow-sm">
        <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
          Upload File
        </h3>
        <form onSubmit={handleSubmit}>
          <div
            className={`dropzone border-2 border-dashed p-6 text-center ${
              formEnabled
                ? "border-green-500 text-green-500 dark:border-green-300 dark:text-green-300"
                : "border-red-300 text-red-500 dark:border-red-500 dark:text-red-500"
            }`}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            {!formEnabled ? (
              <p className="mb-2">
                Drag and drop files here or click to select files
              </p>
            ) : (
              <p className="mb-2">File uploaded</p>
            )}

            <input
              type="file"
              onChange={handleFileChange}
              className="hidden"
              id="fileInput"
            />
            <label htmlFor="fileInput" className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                fill="currentColor"
                className="mx-auto"
                viewBox="0 0 16 16"
              >
                <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z" />
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
              </svg>
            </label>
          </div>

          {errorMessage && <ErrorAlert errorMessage={errorMessage} />}

          <div className="mt-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {/* Vehicle Select */}
              <div>
                <label
                  htmlFor="type"
                  className="block font-bold text-gray-900 dark:text-gray-100"
                >
                  Vehicle
                </label>
                <select
                  id="type"
                  name="type"
                  className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-1 block w-full p-2 rounded"
                  disabled={!formEnabled}
                  required
                >
                  <option value=""></option>
                  <option value="Car">Car</option>
                  <option value="Truck">Truck</option>
                  <option value="Van">Van</option>
                  <option value="Tracktor">Tracktor</option>
                  <option value="Excavator">Excavator</option>
                  <option value="Motorcycle/ATV">Motorcycle/ATV</option>

                  {/* Add more vehicle options here */}
                </select>
              </div>

              {/* Brand Select */}
              <div>
                <label
                  htmlFor="brand"
                  className="block font-bold text-gray-900 dark:text-gray-100"
                >
                  Brand
                </label>
                <select
                  id="brand"
                  name="brand"
                  className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-1 block w-full p-2 rounded"
                  disabled={!formEnabled}
                  required
                >
                  <option value=""></option>
                  <option value="Acura">Acura</option>
                  <option value="AEBI SCHMIDT">AEBI SCHMIDT</option>
                  <option value="AGCO">AGCO</option>
                  <option value="Agrale">Agrale</option>
                  <option value="Alexander Dennis">Alexander Dennis</option>
                  <option value="Alfa Romeo">Alfa Romeo</option>
                  <option value="Aprillia">Aprillia</option>
                  <option value="Arbos">Arbos</option>
                  <option value="Aston Martin">Aston Martin</option>
                  <option value="Astra">Astra</option>
                  <option value="Audi">Audi</option>
                  <option value="Auspicious">Auspicious</option>
                  <option value="Autobianchi">Autobianchi</option>
                  <option value="Baojun">Baojun</option>
                  <option value="Beijing">Beijing</option>
                  <option value="Bentley">Bentley</option>
                  <option value="Bmc">Bmc</option>
                  <option value="BMW">BMW</option>
                  <option value="BMW Moto">BMW Moto</option>
                  <option value="Bobcat">Bobcat</option>
                  <option value="Bouwmann">Bouwmann</option>
                  <option value="Brilliance">Brilliance</option>
                  <option value="Bugatti">Bugatti</option>
                  <option value="Buick">Buick</option>
                  <option value="Cadillac">Cadillac</option>
                  <option value="CAMC">CAMC</option>
                  <option value="Can-am">Can-am</option>
                  <option value="Can-am Seadoo">Can-am Seadoo</option>
                  <option value="Case">Case</option>
                  <option value="Caterpillar">Caterpillar</option>
                  <option value="CF MOTO">CF MOTO</option>
                  <option value="Challenger">Challenger</option>
                  <option value="Chery">Chery</option>
                  <option value="Chevrolet">Chevrolet</option>
                  <option value="Chrysler">Chrysler</option>
                  <option value="Citroen">Citroen</option>
                  <option value="Claas">Claas</option>
                  <option value="Class Area">Class Area</option>
                  <option value="Cummins">Cummins</option>
                  <option value="Cupra">Cupra</option>
                  <option value="Dacia">Dacia</option>
                  <option value="Daewoo">Daewoo</option>
                  <option value="DAF">DAF</option>
                  <option value="Deutz">Deutz</option>
                  <option value="Dodge">Dodge</option>
                  <option value="Dongfeng">Dongfeng</option>
                  <option value="Doosan">Doosan</option>
                  <option value="DSG">DSG</option>
                  <option value="Ducati">Ducati</option>
                  <option value="Faresin">Faresin</option>
                  <option value="FAW">FAW</option>
                  <option value="Fendt">Fendt</option>
                  <option value="Ferrari">Ferrari</option>
                  <option value="Fiat">Fiat</option>
                  <option value="Force">Force</option>
                  <option value="Ford">Ford</option>
                  <option value="Ford Trucks">Ford Trucks</option>
                  <option value="Freightliner">Freightliner</option>
                  <option value="Gamma">Gamma</option>
                  <option value="Gaz">Gaz</option>
                  <option value="Geely">Geely</option>
                  <option value="GEHL">GEHL</option>
                  <option value="Gelly">Gelly</option>
                  <option value="Gillera">Gillera</option>
                  <option value="Ginomoto">Ginomoto</option>
                  <option value="GMC">GMC</option>
                  <option value="Great Wall">Great Wall</option>
                  <option value="Haima">Haima</option>
                  <option value="Halcon">Halcon</option>
                  <option value="Harley Davidson">Harley Davidson</option>
                  <option value="Haval">Haval</option>
                  <option value="Higer Bus">Higer Bus</option>
                  <option value="Hino">Hino</option>
                  <option value="Holden">Holden</option>
                  <option value="Honda">Honda</option>
                  <option value="HOWO">HOWO</option>
                  <option value="Hummer">Hummer</option>
                  <option value="Hymer">Hymer</option>
                  <option value="Hyundai">Hyundai</option>
                  <option value="Hyundai Trucks">Hyundai Trucks</option>
                  <option value="Immo">Immo</option>
                  <option value="Infiniti">Infiniti</option>
                  <option value="International">International</option>
                  <option value="Isuzu">Isuzu</option>
                  <option value="Isuzu Truck">Isuzu Truck</option>
                  <option value="Iveco">Iveco</option>
                  <option value="Iveco Trucks">Iveco Trucks</option>
                  <option value="Jac">Jac</option>
                  <option value="Jac Refine">Jac Refine</option>
                  <option value="Jaguar">Jaguar</option>
                  <option value="Jcb">Jcb</option>
                  <option value="Jeep">Jeep</option>
                  <option value="Jiangling">Jiangling</option>
                  <option value="JMC">JMC</option>
                  <option value="John Deere">John Deere</option>
                  <option value="Joy Long">Joy Long</option>
                  <option value="Kamaz">Kamaz</option>
                  <option value="Kartcross">Kartcross</option>
                  <option value="Kawasaki">Kawasaki</option>
                  <option value="Kawasaki Marine">Kawasaki Marine</option>
                  <option value="Kenworth">Kenworth</option>
                  <option value="Kia">Kia</option>
                  <option value="King Long">King Long</option>
                  <option value="Kioti">Kioti</option>
                  <option value="Komatsu">Komatsu</option>
                  <option value="Krone">Krone</option>
                  <option value="KTM">KTM</option>
                  <option value="Lambordini">Lambordini</option>
                  <option value="Lamborghini">Lamborghini</option>
                  <option value="Lancia">Lancia</option>
                  <option value="Land Rover">Land Rover</option>
                  <option value="Landini">Landini</option>
                  <option value="Laverda">Laverda</option>
                  <option value="LDV">LDV</option>
                  <option value="Lexus">Lexus</option>
                  <option value="Ligier">Ligier</option>
                  <option value="Lincoln">Lincoln</option>
                  <option value="Linder">Linder</option>
                  <option value="London">London</option>
                  <option value="Lotus">Lotus</option>
                  <option value="Luxgen">Luxgen</option>
                  <option value="Macdon">Macdon</option>
                  <option value="Mack">Mack</option>
                  <option value="Mahindra">Mahindra</option>
                  <option value="MAN">MAN</option>
                  <option value="Manitou">Manitou</option>
                  <option value="Maserati">Maserati</option>
                  <option value="Massey Ferguson">Massey Ferguson</option>
                  <option value="Maverick">Maverick</option>
                  <option value="Maxus">Maxus</option>
                  <option value="Maz">Maz</option>
                  <option value="Mazda">Mazda</option>
                  <option value="Mccormick">Mccormick</option>
                  <option value="McLaren">McLaren</option>
                  <option value="Mercedes">Mercedes</option>
                  <option value="Mercedes Truck">Mercedes Truck</option>
                  <option value="Mercury">Mercury</option>
                  <option value="Mercury Marine">Mercury Marine</option>
                  <option value="Merlo">Merlo</option>
                  <option value="MG">MG</option>
                  <option value="Miler">Miler</option>
                  <option value="Miller">Miller</option>
                  <option value="Mini">Mini</option>
                  <option value="Mitsubishi">Mitsubishi</option>
                  <option value="Morgan">Morgan</option>
                  <option value="MTZ">MTZ</option>
                  <option value="MV Augusta">MV Augusta</option>
                  <option value="Neoplan">Neoplan</option>
                  <option value="New Holland">New Holland</option>
                  <option value="Nissan">Nissan</option>
                  <option value="Opel">Opel</option>
                  <option value="Otokar">Otokar</option>
                  <option value="Pellenc">Pellenc</option>
                  <option value="Peterbilt">Peterbilt</option>
                  <option value="Peugeot">Peugeot</option>
                  <option value="Pgo">Pgo</option>
                  <option value="Piaggio">Piaggio</option>
                  <option value="Polaris">Polaris</option>
                  <option value="Ponsse">Ponsse</option>
                  <option value="Porsche">Porsche</option>
                  <option value="Proton">Proton</option>
                  <option value="Renault">Renault</option>
                  <option value="Renault Trucks">Renault Trucks</option>
                  <option value="RIB">RIB</option>
                  <option value="Roewe">Roewe</option>
                  <option value="Rolls Royce">Rolls Royce</option>
                  <option value="Rostselmash">Rostselmash</option>
                  <option value="Rover">Rover</option>
                  <option value="Saab">Saab</option>
                  <option value="Same">Same</option>
                  <option value="Santana">Santana</option>
                  <option value="Scania">Scania</option>
                  <option value="Seadoo">Seadoo</option>
                  <option value="Seat">Seat</option>
                  <option value="Sennebogen">Sennebogen</option>
                  <option value="SERIE 8">SERIE 8</option>
                  <option value="Shelby">Shelby</option>
                  <option value="SINOTRUK">SINOTRUK</option>
                  <option value="Ski-doo">Ski-doo</option>
                  <option value="Skoda">Skoda</option>
                  <option value="Smart">Smart</option>
                  <option value="SMF">SMF</option>
                  <option value="Solaris">Solaris</option>
                  <option value="Ssang Yong">Ssang Yong</option>
                  <option value="Sterling">Sterling</option>
                  <option value="Steyr">Steyr</option>
                  <option value="Subaru">Subaru</option>
                  <option value="Suzuki">Suzuki</option>
                  <option value="Suzuki Marine">Suzuki Marine</option>
                  <option value="Tata">Tata</option>
                  <option value="Tata Trucks">Tata Trucks</option>
                  <option value="Tigercat">Tigercat</option>
                  <option value="Toyota">Toyota</option>
                  <option value="TRIUMPH">TRIUMPH</option>
                  <option value="Troller">Troller</option>
                  <option value="Trumpchi">Trumpchi</option>
                  <option value="UAZ">UAZ</option>
                  <option value="UD">UD</option>
                  <option value="Valtra">Valtra</option>
                  <option value="Vas">Vas</option>
                  <option value="Versitile">Versitile</option>
                  <option value="VM Motori">VM Motori</option>
                  <option value="Volkswagen Trucks">Volkswagen Trucks</option>
                  <option value="Volvo">Volvo</option>
                  <option value="Volvo Industrial">Volvo Industrial</option>
                  <option value="Volvo Marine">Volvo Marine</option>
                  <option value="Volvo Trucks">Volvo Trucks</option>
                  <option value="VW">VW</option>
                  <option value="Weichai">Weichai</option>
                  <option value="XCMG">XCMG</option>
                  <option value="Yamaha">Yamaha</option>
                  <option value="Yanmar">Yanmar</option>
                  <option value="Yuchai">Yuchai</option>
                  <option value="Yutong">Yutong</option>
                  <option value="Zastava">Zastava</option>
                  <option value="Zetor">Zetor</option>
                  <option value="Zhonghua">Zhonghua</option>
                  <option value="Zhongtai">Zhongtai</option>
                  {/* Add more brand options here */}
                </select>
              </div>

              {/* ECU Model Select */}
              <div>
                <label
                  htmlFor="model"
                  className="block font-bold text-gray-900 dark:text-gray-100"
                >
                  ECU Model
                </label>
                <select
                  id="model"
                  name="model"
                  className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-1 block w-full p-2 rounded"
                  disabled={!formEnabled}
                  required
                >
                  <option value=""></option>
                  <option value="Bosch">Bosch</option>
                  <option value="Continental">Continental</option>
                  <option value="Delco">Delco</option>
                  <option value="Delphi">Delphi</option>
                  <option value="Denso">Denso</option>
                  <option value="Deutz">Deutz</option>
                  <option value="FoMoCo">FoMoCo</option>
                  <option value="Heizman">Heizman</option>
                  <option value="Hella">Hella</option>
                  <option value="Hitachi">Hitachi</option>
                  <option value="Honda">Honda</option>
                  <option value="Isuzu">Isuzu</option>
                  <option value="Johnson">Johnson</option>
                  <option value="Kefico">Kefico</option>
                  <option value="Keihin">Keihin</option>
                  <option value="Lotus">Lotus</option>
                  <option value="Lucas">Lucas</option>
                  <option value="Marelli">Marelli</option>
                  <option value="Matsushita">Matsushita</option>
                  <option value="Melco">Melco</option>
                  <option value="Mitsubishi">Mitsubishi</option>
                  <option value="Motorola">Motorola</option>
                  <option value="NGC">NGC</option>
                  <option value="Perkins">Perkins</option>
                  <option value="Phoenix">Phoenix</option>
                  <option value="Saab">Saab</option>
                  <option value="Sagem">Sagem</option>
                  <option value="Siemens">Siemens</option>
                  <option value="Temic">Temic</option>
                  <option value="Transtron">Transtron</option>
                  <option value="Trionic">Trionic</option>
                  <option value="TRW">TRW</option>
                  <option value="Valeo">Valeo</option>
                  <option value="VDO">VDO</option>
                  <option value="Visteon">Visteon</option>
                  <option value="Zexel">Zexel</option>
                  <option value="ZF">ZF</option>
                  {/* Add more ECU model options here */}
                </select>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              {/* ECU Information */}
              <div>
                <label
                  htmlFor="ecu"
                  className="block font-bold text-gray-900 dark:text-gray-100"
                >
                  ECU Information
                </label>
                <input
                  type="text"
                  id="ecu"
                  name="ecu"
                  className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-1 block w-full p-2 rounded"
                  placeholder="EDC16, EDC17, etc..."
                  disabled={!formEnabled}
                  required
                />
              </div>

              {/* Petrol Type */}
              <div>
                <label
                  htmlFor="petrol_type"
                  className="block font-bold text-gray-900 dark:text-gray-100"
                >
                  Petrol Type
                </label>
                <select
                  id="petrol_type"
                  name="petrol_type"
                  className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-1 block w-full p-2 rounded"
                  disabled={!formEnabled}
                  required
                >
                  <option value=""></option>
                  <option value="DIESEL">Diesel</option>
                  <option value="GASOLINE">Gasoline</option>
                </select>
              </div>

              {/* Year */}
              <div>
                <label
                  htmlFor="generation"
                  className="block font-bold text-gray-900 dark:text-gray-100"
                >
                  Year
                </label>
                <select
                  id="generation"
                  name="generation"
                  className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-1 block w-full p-2 rounded"
                  disabled={!formEnabled}
                  required
                >
                  <option value=""></option>
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                  <option value="2017">2017</option>
                  <option value="2016">2016</option>
                  <option value="2015">2015</option>
                  <option value="2014">2014</option>
                  <option value="2013">2013</option>
                  <option value="2012">2012</option>
                  <option value="2011">2011</option>
                  <option value="2010">2010</option>
                  <option value="2009">2009</option>
                  <option value="2008">2008</option>
                  <option value="2007">2007</option>
                  <option value="2006">2006</option>
                  <option value="2005">2005</option>
                  <option value="2004">2004</option>
                  <option value="2003">2003</option>
                  <option value="2002">2002</option>
                  <option value="2001">2001</option>
                  <option value="2000">2000</option>
                  {/* Add more year options here */}
                </select>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              {/* Gear */}
              <div>
                <label
                  htmlFor="gear"
                  className="block font-bold text-gray-900 dark:text-gray-100"
                >
                  Gear
                </label>
                <select
                  id="gear"
                  name="gear"
                  className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-1 block w-full p-2 rounded"
                  disabled={!formEnabled}
                >
                  <option value=""></option>
                  <option value="Manual">Manual</option>
                  <option value="Automatic">Automatic</option>
                </select>
              </div>

              {/* Reading Device */}
              <div>
                <label
                  htmlFor="reading_device"
                  className="block font-bold text-gray-900 dark:text-gray-100"
                >
                  Reading Device
                </label>
                <select
                  id="reading_device"
                  name="reading_device"
                  className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-1 block w-full p-2 rounded"
                  disabled={!formEnabled}
                >
                  <option value=""></option>
                  <option value="Bitbox">Bitbox</option>
                  <option value="Kt200">Kt200</option>
                  <option value="Byteshooter">Byteshooter</option>
                  <option value="Piasini">Piasini</option>
                  <option value="Frieling SPI Wizard">
                    Frieling SPI Wizard
                  </option>
                  <option value="Alientech">Alientech</option>
                  <option value="Dimsport Genius">Dimsport Genius</option>
                  <option value="PEmicro">PEmicro</option>
                  <option value="MPPS">MPPS</option>
                  <option value="Magic Motorsport">Magic Motorsport</option>
                  <option value="EVC">EVC</option>
                  <option value="Frieling i-Flash">Frieling i-Flash</option>
                  <option value="Frieling i-Boot">Frieling i-Boot</option>
                  <option value="Dimsport New Trasdata">
                    Dimsport New Trasdata
                  </option>
                  <option value="Autotuner">Autotuner</option>
                  <option value="PCM-Flash">PCM-Flash</option>
                  <option value="Femto">Femto</option>
                  <option value="bFlash">bFlash</option>
                  <option value="CMD">CMD</option>
                  <option value="DFOX">DFOX</option>
                  {/* Add more reading devices here */}
                </select>
              </div>

              {/* Expected */}
              <div>
                <label
                  htmlFor="expected"
                  className="block font-bold text-gray-900 dark:text-gray-100"
                >
                  Expected
                </label>
                <select
                  id="expected"
                  name="expected"
                  className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-1 block w-full p-2 rounded"
                  disabled={!formEnabled}
                  required
                >
                  <option value="asap">ASAP</option>
                  <option value="1-2-hours">1-2 hours</option>
                </select>
              </div>
            </div>

            {/* Choose Solution & DTC Button */}
            <div className="mt-4">
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setShowModal(true)}
                disabled={!formEnabled}
              >
                Choose Solution & DTC
              </button>
            </div>

            {/* Modal */}
            {showModal && (
              <ChooseSolution
                setShowModal={setShowModal}
                setSelectedOptions={setSelectedOptions}
                setSelectedSolutionIds={setSelectedSolutionIds}
              />
            )}

            <div className="mt-6">
              <textarea
                className="border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 mt-1 block w-full p-2 rounded"
                rows="4"
                placeholder="Selected options will appear here..."
                value={selectedOptions.join("\n")}
                readOnly
                required
              />
            </div>

            {/* Submit Button */}
            <div className="mt-4">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex"
                disabled={!file}
              >
                <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
                {isLoading ? "Uploading..." : "Upload"}
              </button>
            </div>
          </div>
        </form>
        {/* Success Modal */}
        {successModal && <SuccessModal isModal={true} />}
        {/* Success Modal */}
        {errorModal && (
          <ErrorModal
            errorMessage={
              "There was an error uploading your file. Please try again."
            }
          />
        )}
      </div>
    </div>
  );
};

export default FileUpload;
