import React from "react";

const Loader = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="relative w-16 h-16">
        <div className="absolute border-4 border-blue-500 dark:border-blue-300 border-t-transparent dark:border-t-transparent border-solid rounded-full w-full h-full animate-spin"></div>
        <div className="absolute border-4 border-blue-300 dark:border-blue-500 border-b-transparent dark:border-b-transparent border-solid rounded-full w-10 h-10 top-3 left-3 animate-spin-reverse"></div>
      </div>
    </div>
  );
};

export default Loader;
