import axios from "axios";
import { handle403Error } from "./handle403Error";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
  timeout: 40000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      handle403Error(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
