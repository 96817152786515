import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PayPalForm from "../components/PayPalForm";
import SuccessModal from "../components/SuccessModal";
import { AuthContext } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";

const BuyCredits = () => {
  const { user, updateUser, updateUserLocal } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const myBooleanParam = queryParams.get("success") === "true";

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get("/users/my");
        const updatedUser = {
          ...user,
          credit_balance: response.data.credit_balance,
        };
        updateUser(updatedUser);
        updateUserLocal();
      } finally {
        // Optionally handle any cleanup or final logic here
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    if (myBooleanParam) {
      const timer = setTimeout(() => {
        navigate("/upload");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [myBooleanParam, navigate]);

  return (
    <div className="max-w-md mt-8 mx-auto bg-gray-100 dark:bg-gray-600 text-gray-900 dark:text-gray-200 rounded text-center">
      {myBooleanParam ? (
        <SuccessModal message={"You bought credits successfully"} />
      ) : (
        <div className="py-8">
          <h2 className="text-2xl mb-4">Buy Credits</h2>
          <PayPalForm userId={user.id} />
        </div>
      )}
    </div>
  );
};

export default BuyCredits;
