import React, { useState, useEffect, useContext, useRef } from "react";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import { AuthContext } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";
import { getStatusBadgeClass } from "../utils/Utils";

const SupportChatBox = ({ ticketId, role }) => {
  const [newMessage, setNewMessage] = useState("");
  const [ticket, setTicket] = useState();
  const [messages, setMessages] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [canSend, setCanSend] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [closeTicketLoading, setCloseTicketLoading] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { user } = useContext(AuthContext);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get(`/tickets/${ticketId}`);
        setTicket(response.data);
        setMessages(response.data.ticket_messages);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, [ticketId]);

  useEffect(() => {
    scrollToBottom();
    handleCanSend(messages);
  }, [messages]);

  const handleCanSend = (allMessages) => {
    const sortedMessages = [...allMessages].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );

    if (sortedMessages.length > 0) {
      const lastMessage = sortedMessages[0];

      if (ticket.status === "CLOSED") {
        setCanSend(false);
        return;
      }

      if (lastMessage.user_id !== user.id) {
        setCanSend(true);
      } else {
        if (sortedMessages.length > 2) {
          const clientMessageCount = sortedMessages
            .slice(0, 3)
            .filter((message) => message.user_id === user.id).length;

          if (clientMessageCount < 3) {
            setCanSend(true);
            return;
          } else {
            setCanSend(false);
          }
        }
      }
    } else {
      setCanSend(true);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const sendMessage = async () => {
    if (newMessage.trim() !== "") {
      setSendMessageLoading(true);
      const messageData = {
        ticket_id: ticketId,
        user_id: user.id,
        message: newMessage,
        file_name: attachment ? attachment.name : "",
        created_at: new Date().toLocaleString(),
      };

      const formData = new FormData();
      formData.append("jsonData", JSON.stringify(messageData));

      if (attachment) {
        formData.append("file", attachment);
      }

      try {
        const response = await axiosInstance.post(
          `/tickets/${ticketId}/send-message`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const newMessages = [...messages, response.data];

        setMessages(newMessages);

        setNewMessage("");
        setAttachment(null);
        handleCanSend(newMessages);
        setSendMessageLoading(false);
      } catch (error) {
        setSendMessageLoading(false);
        console.error("Error sending message:", error);
      }
    }
  };

  const handleAttachment = (e) => {
    setAttachment(e.target.files[0]); // Get the selected file
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const handleCloseTicket = async () => {
    try {
      setCloseTicketLoading(true);
      await axiosInstance.put(`/tickets/${ticket.id}/close`);
    } finally {
      setShowCloseModal(false);
      setTicket({ ...ticket, status: "CLOSED" });
      setCloseTicketLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  const isMessageFromMe = (message) => message.user_id === user.id;

  return (
    <div className="">
      <div className="w-full p-4 rounded-lg bg-white dark:bg-gray-800 dark:border-gray-700">
        <div className="flex justify-between items-center mb-3">
          <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
            Support Chat{" "}
            {ticket?.status === "CLOSED" && (
              <span
                className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                  "CLOSED"
                )}`}
              >
                Closed
              </span>
            )}
          </h4>

          {/* Three-dot dropdown */}
          {ticket?.status !== "CLOSED" && (
            <div className="relative">
              <button
                onClick={() => setShowDropdown(!showDropdown)}
                className="p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="4" // Increase stroke width for larger dots
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-700 dark:text-gray-300" // Adjust width and height for overall size
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v.01M12 12v.01M12 19.5v.01"
                  />
                </svg>
              </button>

              {/* Dropdown menu */}
              {showDropdown && (
                <div className="absolute right-0 mt-2 w-40 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md shadow-lg z-10">
                  {ticket.status !== "CLOSED" && (
                    <button
                      onClick={() => {
                        setShowCloseModal(true);
                        setShowDropdown(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-red-600 dark:text-red-400 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-md"
                    >
                      Close Ticket
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="mb-3 h-40 overflow-y-scroll border-b border-gray-300 dark:border-gray-600 pb-3">
          {messages && messages.length > 0 ? (
            messages.map((msg, index) => (
              <div
                key={index}
                className={`flex flex-col mb-2 ${
                  isMessageFromMe(msg) ? "items-end" : "items-start"
                }`}
              >
                <div className="text-sm font-semibold mb-1 text-gray-600 dark:text-gray-300">
                  {isMessageFromMe(msg)
                    ? "Me"
                    : role.toUpperCase() === "ADMIN"
                    ? "Client"
                    : "Support Agent"}{" "}
                </div>
                <div
                  className={`p-2 rounded-md max-w-xs break-words overflow-wrap w-fit ${
                    isMessageFromMe(msg)
                      ? "bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-100"
                      : "bg-blue-600 text-white"
                  }`}
                >
                  {msg.message}
                  {msg.file_name && (
                    <div className="mt-1 text-sm text-blue-500">
                      Attachment: {msg.file_name}
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500 dark:text-gray-400">
              No messages yet...
            </p>
          )}
          <div ref={messagesEndRef} />
        </div>

        <div className="mb-3 flex items-center">
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyPress}
            rows="1"
            className="flex-grow p-2 border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-100 rounded-md focus:outline-none focus:border-blue-500 dark:focus:border-blue-400"
            placeholder="Type your message here..."
            disabled={!canSend}
          />
          <label
            htmlFor="fileInput"
            className="ml-2 cursor-pointer flex items-center p-2 rounded-full bg-gray-100 dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
              />
            </svg>
          </label>
          <input
            id="fileInput"
            type="file"
            onChange={handleAttachment}
            className="hidden"
            disabled={!canSend}
          />
        </div>

        <button
          onClick={sendMessage}
          className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
          disabled={!canSend || sendMessageLoading}
        >
          {sendMessageLoading ? "Sending..." : "Send Message"}
        </button>
      </div>

      {showCloseModal && (
        <Modal
          title="Close Ticket"
          content={`Are you sure you want to close the ticket?`}
          onConfirm={handleCloseTicket}
          onCancel={() => setShowCloseModal(false)}
          action={closeTicketLoading ? "Closing..." : "Yes"}
          disabled={closeTicketLoading}
        />
      )}
    </div>
  );
};

export default SupportChatBox;
