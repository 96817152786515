import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import axiosInstance from "../../utils/axiosInstance";

const CreditsLedger = () => {
  const [credits, setCredits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const itemsPerPage = 10;

  useEffect(() => {
    const fetchCredits = async () => {
      setLoading(true);
      try {
        const offset = (currentPage - 1) * itemsPerPage;
        const response = await axiosInstance.get(
          `/credits-ledger?limit=${itemsPerPage}&offset=${offset}`
        );
        setCredits(response.data.credits_ledger);

        // Calculate the total number of pages
        const totalRows = response.data.total_rows;
        setTotalPages(Math.ceil(totalRows / itemsPerPage));
      } catch (error) {
        console.error("Error fetching data", error);
      }
      setLoading(false);
    };
    fetchCredits();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Credits Ledger</h1>
      {
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead>
              <tr className="w-full bg-gray-100 text-left">
                <th className="px-4 py-2">User ID</th>
                <th className="px-4 py-2">UserName</th>
                <th className="px-4 py-2">Credit Balance</th>
                <th className="px-4 py-2">Source</th>
                <th className="px-4 py-2">Created At</th>
              </tr>
            </thead>
            <tbody>
              {credits.map((credit) => (
                <tr key={credit.id} className="border-b">
                  <td className="px-4 py-2">{credit.user_id}</td>
                  <td className="px-4 py-2">{credit.username}</td>
                  <td
                    className={`px-4 py-2 font-semibold ${
                      credit.credit_balance >= 0
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {credit.credit_balance}
                  </td>
                  <td className="px-4 py-2">{credit.source}</td>
                  <td className="px-4 py-2">
                    {new Date(credit.created_at).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }

      <div className="flex justify-center items-center space-x-2 mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`px-3 py-1 rounded ${
              currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CreditsLedger;
