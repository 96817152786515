import {
  FaFacebookF,
  FaTiktok,
  FaYoutube,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-4 text-center border-t-2 border-indigo-600">
      <div className="flex justify-between px-3 items-end">
        <div id="license">
          <a
            href="https://www.evc.de/en/check_evc_license.asp?k=GjDDuek8kp03z6%2fqSy8Ekw%3d%3d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://www.evc.de/common/check_evc_license_image.asp?k=GjDDuek8kp03z6%2fqSy8Ekw%3d%3d"
              alt="License Check"
            />
          </a>
        </div>
        <p>© 2024 VDP Tuning. All rights reserved.</p>
        <div className="flex space-x-4">
          <a
            href="https://www.instagram.com/vajdin_dino_pirok/profilecard/?igsh=M3ZlbnozaGFsdjBm"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
            className="hover:text-indigo-400"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/share/E7AMXBwjfXJYhtCV/?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
            className="hover:text-indigo-400"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://wa.me/message/NMGM4H3DCGB2C1"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
            className="hover:text-indigo-400"
          >
            <FaWhatsapp />
          </a>
          <a
            href="https://www.tiktok.com/@vajdin_dino_pirok?_t=8rLOkEZBmG0&_r=1"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
            className="hover:text-indigo-400"
          >
            <FaTiktok />
          </a>
          <a
            href="https://youtube.com/@vdptuning?si=QGuE7IO2reV5z18u"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
            className="hover:text-indigo-400"
          >
            <FaYoutube />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
