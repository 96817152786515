import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";
import ErrorAlert from "./ErrorAlert";

const CancelFileButton = ({ fileId, status, credits, isAdmin }) => {
  const { user, updateUser, updateUserLocal } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();

  const handleCancel = async () => {
    try {
      setLoading(true);
      await axiosInstance.put(`/files/${fileId}/cancel`);
      setLoading(false);
      if (!isAdmin) {
        user.credit_balance += credits;
        updateUser(user);
        updateUserLocal();
        navigate("/my-files");
      } else {
        navigate("/admin/all-files");
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status === 400) {
        setErrorMessage(error.response.data.message);
        return;
      }
      setErrorMessage("Something went wrong");
    }
  };

  return (
    <div className="mt-6">
      {errorMessage && (
        <div>
          {" "}
          <ErrorAlert errorMessage={errorMessage} /> <br />{" "}
        </div>
      )}
      {isAdmin || (status && status == "PENDING") || status == "IN_PROGRESS" ? (
        <button
          onClick={handleCancel}
          disabled={loading}
          className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg flex items-center gap-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 4a2 2 0 012-2h10a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V4zm5 4a1 1 0 000 2h4a1 1 0 000-2H8z"
              clipRule="evenodd"
            />
          </svg>
          {loading ? "Canceling..." : "Click here to cancel"}
        </button>
      ) : (
        ((!isAdmin && status === "CANCELED") || status === "REFUNDED") && (
          <p className="text-red-300 mt-2">
            Your file is already canceled and you are refunded
          </p>
        )
      )}
    </div>
  );
};

export default CancelFileButton;
