import { useState, useEffect } from "react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../../utils/axiosInstance";
import SolutionImageModal from "../admin/components/SolutionImageModal";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";

const SolutionImage = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState();
  const [showAddImageModal, setShowAddImageModal] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null); // For tracking hovered image
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axiosInstance.get("/solutions/images");
        setImages(response.data);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchImages();
  }, []);

  const handleAddImageClick = () => {
    setShowAddImageModal(true);
  };

  // Handle image delete
  const handleDeleteImageClick = (image) => {
    setImageToDelete(image);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`/solutions/images/${imageToDelete.id}`);
      setImages(images.filter((img) => img.id !== imageToDelete.id));
      setShowDeleteModal(false);
    } catch (err) {
      setError("Failed to delete image.");
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Solution Images</h1>

        <div className="flex space-x-2">
          <button
            onClick={handleAddImageClick}
            className="flex items-center bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Add Image Solution
          </button>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg">
        <div className="grid grid-cols-5 gap-4">
          {images.map((image) => (
            <div
              key={image.id}
              className="relative cursor-pointer border border-gray-300 rounded-md shadow-md p-2 hover:shadow-lg transition-shadow"
              onMouseEnter={() => setHoveredImage(image.id)}
              onMouseLeave={() => setHoveredImage(null)}
            >
              <img
                src={image.url}
                alt={image.name}
                className="h-32 w-full object-cover rounded-md"
              />
              {hoveredImage === image.id && (
                <div className="absolute top-2 right-2">
                  <button
                    onClick={() => handleDeleteImageClick(image)}
                    className="bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Add Image Modal */}
        {showAddImageModal && (
          <SolutionImageModal setShowAddImageModal={setShowAddImageModal} />
        )}

        {/* Delete Confirmation Modal */}
        {showDeleteModal && (
          <Modal
            title="Delete Image"
            content={`Are you sure you want to delete this image?`}
            onConfirm={handleConfirmDelete}
            onCancel={() => setShowDeleteModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default SolutionImage;
