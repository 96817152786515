import React from "react";
import { Link } from "react-router-dom";

const CreditBalanceBadge = ({ balance }) => {
  const badgeColor = balance === 0 ? "bg-red-500" : "bg-white";
  const textColor = balance === 0 ? "text-white" : "text-blue-500";

  return (
    <Link
      to={"/buy-credits"}
      title="Click to buy Credits"
      className="flex justify-between items-center bg-blue-500 dark:bg-gray-700 text-white rounded-full shadow-md"
    >
      <div className="font-semibold px-2">CRS</div>
      <div
        className={`${badgeColor} ${textColor} font-bold px-3 py-1 rounded-full`}
      >
        {balance && balance.toFixed(2)}
      </div>
    </Link>
  );
};

export default CreditBalanceBadge;
