import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { AuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";
import { getStatusBadgeClass, truncateText } from "../utils/Utils";
import { EyeIcon } from "@heroicons/react/24/outline";

const MyTicket = () => {
  const { user } = useContext(AuthContext);
  const [tickets, setTickets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axiosInstance.get(`/users/${user.id}/tickets`);
        setTickets(response.data);
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [user.id]);

  const filteredTickets = tickets.filter(
    (ticket) =>
      ticket.ticket_id
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      ticket.subject.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 dark:bg-gray-900 dark:text-white">
      <h1 className="text-2xl font-bold mb-4">My Tickets</h1>

      {/* Search Bar */}
      <div className="mb-4">
        <input
          type="text"
          className="border border-gray-300 p-2 w-full rounded-md dark:border-gray-600 dark:bg-gray-800 dark:text-white"
          placeholder="Search by ticket id, subject..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Display message if there are no tickets */}
      {filteredTickets.length === 0 ? (
        <div className="text-center text-gray-500 dark:text-gray-400 mt-8">
          No tickets found.
        </div>
      ) : (
        /* Table for displaying tickets */
        <table className="min-w-full table-auto bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-700">
              <th className="px-4 py-2 border-b text-left dark:border-gray-600">
                Ticket ID
              </th>
              <th className="px-4 py-2 border-b text-left dark:border-gray-600">
                Subject
              </th>
              <th className="px-4 py-2 border-b text-left dark:border-gray-600">
                Status
              </th>
              <th className="px-4 py-2 border-b text-left dark:border-gray-600">
                Opening Date
              </th>
              <th className="px-4 py-2 border-b text-left dark:border-gray-600">
                Closing Date
              </th>
              <th className="px-4 py-2 border-b text-left text-center align-middle dark:border-gray-600">
                View
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredTickets.map((ticket) => (
              <tr
                key={ticket.ticket_id}
                className="hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <td className="px-4 py-2 border-b dark:border-gray-600">
                  {ticket.ticket_id}
                </td>
                <td className="px-4 py-2 border-b dark:border-gray-600">
                  {truncateText(ticket.subject, 35)}
                </td>
                <td className="px-4 py-2 border-b dark:border-gray-600">
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                      ticket.status
                    )}`}
                  >
                    {ticket.status}
                  </span>
                </td>
                <td className="px-4 py-2 border-b dark:border-gray-600">
                  {ticket.opening_date}
                </td>
                <td className="px-4 py-2 border-b dark:border-gray-600">
                  {ticket.closing_date || "N/A"}
                </td>
                <td className="px-4 py-2 border-b text-center align-middle dark:border-gray-600">
                  <button className="text-blue-500 dark:text-blue-400 py-1 rounded">
                    <Link to={`/view-file/${ticket.file_id}`}>
                      <EyeIcon className="h-5 w-5 mr-1" />
                    </Link>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MyTicket;
