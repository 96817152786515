import React, { useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import Notification from "./Notification";

// Modal Component
function Modal({ show, onClose, children }) {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <button
          onClick={onClose}
          className="text-red-500 text-right float-right mb-4"
        >
          Close
        </button>
        {children}
      </div>
    </div>
  );
}

// UploadModFileButton Component with Modal integration
function UploadModFileModal({ fileId }) {
  const [showModal, setShowModal] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [modFile, setModFile] = useState(null);
  const [additionalInput, setAdditionalInput] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleFileChange = (event) => {
    setModFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (modFile) {
      try {
        setUploadStatus("Uploading...");

        const formData = new FormData();
        formData.append("file", modFile);
        formData.append("fileId", fileId);
        formData.append("notes", additionalInput);

        const response = await axiosInstance.post(
          `/files/${fileId}/upload-mod-file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        await new Promise((resolve) => setTimeout(resolve, 2000));
        setShowNotification(true);
        setMessage("Uploaded successfully.");
        setShowModal(false);
      } catch (error) {
        setMessage("Upload failed. Please try again.");
        setIsError(true);
      }
    } else {
      setUploadStatus("Please select a file before uploading.");
    }
  };

  return (
    <div className="mt-6">
      <button
        onClick={() => setShowModal(true)}
        className="bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded-lg flex items-center gap-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3 4a2 2 0 012-2h10a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V4zm5 4a1 1 0 000 2h4a1 1 0 000-2H8z"
            clipRule="evenodd"
          />
        </svg>
        Upload Modified File
      </button>

      {showNotification && (
        <Notification
          setShowNotification={setShowNotification}
          message={message}
          isError={isError}
        />
      )}

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <div className="mt-4">
          <label className="block mb-2 font-semibold" htmlFor="additionalInput">
            Additional Input
          </label>
          <input
            type="text"
            id="additionalInput"
            value={additionalInput}
            onChange={(e) => setAdditionalInput(e.target.value)}
            className="block w-full mb-4 p-2 border rounded"
          />

          <label className="block mb-2 font-semibold" htmlFor="modFileUpload">
            Upload Mod File
          </label>
          <input
            type="file"
            id="modFileUpload"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 mb-4"
          />

          <button
            onClick={handleUpload}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg"
          >
            Upload
          </button>

          {uploadStatus && (
            <p className="mt-2 text-sm bg-gray">{uploadStatus}</p>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default UploadModFileModal;
