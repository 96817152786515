import React from "react";

function PayPalForm({ userId }) {
  return (
    <div id="paypal-button-container">
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="HJJFAY254W64W" />
        <input type="hidden" name="currency_code" value="EUR" />
        <input
          type="image"
          src="https://www.paypalobjects.com/en_US/i/btn/btn_paynowCC_LG.gif"
          border="0"
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Buy Now"
        />
        <input type="hidden" name="custom" value={userId} />
      </form>
    </div>
  );
}

export default PayPalForm;
