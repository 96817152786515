import { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";
import Loader from "../components/Loader";
import { countryOptions } from "../utils/Countries";

const AccountSettings = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [country, setCountry] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");

  const [loading, setLoading] = useState(true);
  const [changingPassword, setChangingPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [activeTab, setActiveTab] = useState("personal");

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    const countryData = countryOptions.find(
      (option) => option.name === selectedCountry
    );
    setCountry(selectedCountry);
    setPhoneCode(countryData ? countryData.code : "");
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get("/users/my");
        setUsername(response.data.username);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setCountry(response.data.country);
        const countryData = countryOptions.find(
          (option) => option.name === response.data.country
        );
        setPhoneCode(countryData ? countryData.code : "");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, []);

  const handlePersonalInfoSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axiosInstance.put("/users/my", {
        username: username,
        phone: `${phoneCode}${phone}`,
        country: country,
      });

      if (response.status === 200) {
        setSuccess("Details updated successfully!");
      } else {
        setError("Failed to update details.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 400
      ) {
        setError(error.response.data.message);
        return;
      }
      setError("An error occurred while updating.");
    } finally {
      setLoading(false);
    }
  };

  const evaluatePasswordStrength = (password) => {
    let strength = "weak";
    if (password.length >= 8) {
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumber = /\d/.test(password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      if (hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar) {
        strength = "strong";
      } else if (hasUpperCase || hasNumber || hasSpecialChar) {
        strength = "normal";
      }
    }
    return strength;
  };

  const handleNewPasswordChange = (e) => {
    const pwd = e.target.value;
    setNewPassword(pwd);
    const strength = evaluatePasswordStrength(pwd);
    setPasswordStrength(strength);
  };

  const handlePasswordChangeSubmit = async (e) => {
    e.preventDefault();
    setChangingPassword(true);
    setError(null);
    setSuccess(null);

    if (newPassword !== confirmNewPassword) {
      setError("New passwords do not match.");
      setChangingPassword(false);
      return;
    }

    try {
      const response = await axiosInstance.put("/users/change-password", {
        current_password: currentPassword,
        new_password: newPassword,
      });

      if (response.status === 200) {
        setSuccess("Password changed successfully!");
      } else {
        setError("Failed to change password.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 400
      ) {
        setError(error.response.data.message);
        return;
      }
      setError("An error occurred while changing password.");
    } finally {
      setChangingPassword(false);
    }
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case "strong":
        return "bg-green-500";
      case "normal":
        return "bg-yellow-500";
      case "weak":
      default:
        return "bg-red-500";
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl mb-6 text-gray-900 dark:text-gray-100">
        Account Settings
      </h1>

      {/* Tabs */}
      <div className="flex border-b mb-6 border-gray-300 dark:border-gray-700">
        <button
          className={`mr-4 pb-2 text-gray-900 dark:text-gray-100 ${
            activeTab === "personal" ? "border-b-2 border-blue-500" : ""
          }`}
          onClick={() => setActiveTab("personal")}
        >
          Personal Information
        </button>
        <button
          className={`mr-4 pb-2 text-gray-900 dark:text-gray-100 ${
            activeTab === "password" ? "border-b-2 border-blue-500" : ""
          }`}
          onClick={() => setActiveTab("password")}
        >
          Change Password
        </button>
      </div>

      {/* Personal Information Tab */}
      {activeTab === "personal" && (
        <div>
          <h2 className="text-xl mb-4 text-gray-900 dark:text-gray-100">
            Edit Personal Information
          </h2>
          {error && (
            <div className="bg-red-100 text-red-700 p-2 rounded mb-4">
              {error}
            </div>
          )}
          {success && (
            <div className="bg-green-100 text-green-700 p-2 rounded mb-4">
              {success}
            </div>
          )}
          <form onSubmit={handlePersonalInfoSubmit}>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
                Username (Not Changeable)
              </label>
              <div className="w-full p-2 border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-400 rounded">
                {username}
              </div>
            </div>

            {/* Info Box for Email */}
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
                Email (Not Changeable)
              </label>
              <div className="w-full p-2 border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-400 rounded">
                {email}
              </div>
            </div>

            {/* Country, Phone Code, and Phone in one row */}
            <div className="mb-4 flex space-x-2">
              <div className="w-1/3">
                <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
                  Country
                </label>
                <select
                  value={country}
                  onChange={handleCountryChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  required
                >
                  <option value="" disabled>
                    Select Country
                  </option>
                  {countryOptions.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-1/4">
                <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
                  Phone Code
                </label>
                <input
                  type="text"
                  value={phoneCode}
                  placeholder="+49"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  readOnly
                />
              </div>

              <div className="w-2/4">
                <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
                  Phone Number
                </label>
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-400"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Changes"}
            </button>
          </form>
        </div>
      )}

      {/* Change Password Tab */}
      {activeTab === "password" && (
        <div>
          <h2 className="text-xl mb-4 text-gray-900 dark:text-gray-100">
            Change Password
          </h2>
          {error && (
            <div className="bg-red-100 text-red-700 p-2 rounded mb-4">
              {error}
            </div>
          )}
          {success && (
            <div className="bg-green-100 text-green-700 p-2 rounded mb-4">
              {success}
            </div>
          )}
          <form onSubmit={handlePasswordChangeSubmit}>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
                Current Password
              </label>
              <input
                type="password"
                className="w-full p-2 border rounded border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Enter current password"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
                New Password
              </label>
              <input
                type="password"
                className="w-full p-2 border rounded border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                value={newPassword}
                onChange={handleNewPasswordChange}
                placeholder="Enter new password"
                required
              />
              {/* Password strength indicator */}
              <div
                className={`h-2 mt-2 transition-all duration-300 ${getPasswordStrengthColor()}`}
              ></div>
              <p className="mt-1 text-gray-500 dark:text-gray-400">
                {passwordStrength
                  ? `Password strength: ${passwordStrength}`
                  : ""}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
                Confirm New Password
              </label>
              <input
                type="password"
                className="w-full p-2 border rounded border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                placeholder="Confirm new password"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-400"
              disabled={changingPassword}
            >
              {changingPassword ? "Saving..." : "Change Password"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default AccountSettings;
