import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";

const AddSolution = () => {
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    credits: "",
    type: "TUNING",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axiosInstance.get("/solutions/images");
        setImages(response.data);
      } finally {
      }
    };
    fetchImages();
  }, []);

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle image selection
  const handleImageSelect = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowImageModal(false);
    setFormData({
      ...formData,
      imageUrl, // Adding the selected image URL to formData
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await axiosInstance.post("/solutions", {
        name: formData.name,
        code: formData.code,
        credits: parseInt(formData.credits, 10),
        type: formData.type,
        order_no: formData.order_no,
        image_url: selectedImageUrl,
      });
      setLoading(false);
      navigate("/admin/solutions");
    } catch (err) {
      setError("Failed to add solution. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Add New Solution</h1>

      {/* Form */}
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded-lg shadow-md"
      >
        {/* Existing fields (name, code, credits, etc.) */}
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-gray-700 font-semibold mb-2"
          >
            Solution Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="code"
            className="block text-gray-700 font-semibold mb-2"
          >
            Code
          </label>
          <input
            type="text"
            id="code"
            name="code"
            value={formData.code}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="credits"
            className="block text-gray-700 font-semibold mb-2"
          >
            Credits
          </label>
          <input
            type="number"
            id="credits"
            name="credits"
            value={formData.credits}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="type"
            className="block text-gray-700 font-semibold mb-2"
          >
            Type
          </label>
          <select
            id="type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="TUNING">TUNING</option>
            <option value="OPTION">OPTION</option>
          </select>
        </div>

        <div className="mb-4">
          <label
            htmlFor="order_no"
            className="block text-gray-700 font-semibold mb-2"
          >
            Order Number
          </label>
          <input
            type="number"
            id="order_no"
            name="order_no"
            value={formData.order_no}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {/* Image Selection */}
        <div className="mb-4">
          <label
            htmlFor="image"
            className="block text-gray-700 font-semibold mb-2"
          >
            Select Image
          </label>
          <button
            type="button"
            onClick={() => setShowImageModal(true)} // Open image modal
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            {selectedImageUrl ? "Change Image" : "Select Image"}
          </button>

          {selectedImageUrl && (
            <div className="mt-4">
              <p>Selected Image:</p>
              <img
                src={selectedImageUrl}
                alt="Selected"
                className="w-32 h-32 object-cover border"
              />
            </div>
          )}
        </div>

        {/* Error Message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            className={`px-4 py-2 text-white rounded-md ${
              loading ? "bg-gray-400" : "bg-green-500 hover:bg-green-600"
            } focus:outline-none`}
            disabled={loading}
          >
            {loading ? "Adding..." : "Add Solution"}
          </button>
        </div>
      </form>

      {/* Image Modal */}
      {showImageModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 className="text-xl font-bold mb-4">Select an Image</h2>
            <div className="grid grid-cols-4 gap-4 h-auto max-h-[80vh] overflow-y-auto">
              {images.map((image) => (
                <div key={image.id} className="cursor-pointer">
                  <img
                    src={image.url}
                    alt={image.name}
                    className="w-full h-32 object-cover border"
                    onClick={() => handleImageSelect(image.url)}
                  />
                </div>
              ))}
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-red-500 text-white rounded"
                onClick={() => setShowImageModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddSolution;
