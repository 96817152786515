import React from "react";

const ErrorAlert = ({ errorMessage }) => {
  return (
    <div
      className="bg-red-100 dark:bg-red-900 border border-red-400 dark:border-red-600 text-red-700 dark:text-red-300 px-4 py-3 rounded relative mt-2"
      role="alert"
    >
      <span className="block sm:inline">{errorMessage}</span>
      <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
        <svg
          className="fill-current h-6 w-6 text-red-500 dark:text-red-300"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 9l5 5m0-5l-5 5m-5-5l5-5m0 5l-5-5"
            clipRule="evenodd"
          />
        </svg>
      </span>
    </div>
  );
};

export default ErrorAlert;
